import { useAppSelector } from '@hooks/useAppSelector';
import {
  COIN_PACK_CAMPAIGN_TOPIC_ID,
  EARLY_BIRD_TOPIC_ID,
  FLIGHT_TICKET_PRIZE_CAMPAIGN_START_DATE,
  FLIGHT_TICKET_PRIZE_CAMPAIGN_STOP_DATE,
  SIGN_UP_CAMPAIGN_CODE,
  SIGN_UP_CAMPAIGN_DISCOUNT_AMOUNT,
} from '@utils/constants';
import {
  useIsEarlyBirdActiveAndCanBeEligible,
  useShowEarlyBirdPreAnnouncement,
} from '@utils/early-bird';
import { useIsJapanUser } from '@utils/hooks';
import routes, { route } from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { useRouter } from 'next/router';
import { useActiveCoinPack } from './useActiveCoinPack';
import { useAppStore } from './useAppStore';
import useLocation from './useLocation';
import { useNeighbor } from './useNeighbor';
import useRegistrationCountry from './useRegistrationCountry';

export const useCampaignImagePath = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return ({ type }: { type: 'earlybird' | 'signup' }) => {
    if (type === 'earlybird') {
      return `/images/campaign/early-bird/${campaigns?.early_bird?.code}/`;
    }

    if (type === 'signup') {
      return `/images/campaign/signup/${SIGN_UP_CAMPAIGN_CODE}/`;
    }
  };
};

export const useIsFlightTicketPrizeCampaignActive = () => {
  const isJapanUser = useIsJapanUser();
  const now = Date.now();

  return (
    isJapanUser &&
    now >= Date.parse(FLIGHT_TICKET_PRIZE_CAMPAIGN_START_DATE) &&
    now <= Date.parse(FLIGHT_TICKET_PRIZE_CAMPAIGN_STOP_DATE)
  );
};

export const useShowFlightTicketPrizeCampaign = () => {
  const isFlightTicketPrizeCampaignActive =
    useIsFlightTicketPrizeCampaignActive();

  const { isSignUpCoinPackageActive } = useActiveCoinPack();

  return isFlightTicketPrizeCampaignActive && !isSignUpCoinPackageActive;
};

export const useGetCampaignBanners = () => {
  const { locale } = useRouter();
  const { isLoggedIn, vipRank } = useNeighbor();

  const showEarlyBirdPreAnnouncement = useShowEarlyBirdPreAnnouncement();
  const isEarlyBirdActiveAndCanBeEligible =
    useIsEarlyBirdActiveAndCanBeEligible();
  const showFlightTicketPrizeCampaignActive =
    useShowFlightTicketPrizeCampaign();

  const campaignImagePath = useCampaignImagePath();

  const {
    activeCoinPackImagePath,
    isCoinPackCampaignActive,
    showCoinPackPreAnnouncement,
  } = useActiveCoinPack();

  const banners = [];

  // Early Bird - Pre banner
  if (showEarlyBirdPreAnnouncement) {
    banners.push({
      alt: 'pre early birds',
      link: route(routes.topic, { id: EARLY_BIRD_TOPIC_ID }),
      src: `${campaignImagePath({ type: 'earlybird' })}pre-banner.png?v=3`,
      type: 'earlybird',
    });
  } else if (isEarlyBirdActiveAndCanBeEligible) {
    // Early Bird - Banner
    banners.push({
      alt: 'early bird',
      link: route(routes.topic, {
        id: EARLY_BIRD_TOPIC_ID,
      }),
      src: `${campaignImagePath({
        type: 'earlybird',
      })}banner-${vipRank}-${locale}.png`,
      type: 'earlybird',
    });
  }

  // FIXME: Hide until new design is created
  // if (isSignUpCoinPackageActive) {
  //   banners.push({
  //     alt: 'coin pack',
  //     link: routes.coinHistory,
  //     src: `${activeCoinPackImagePath}banner.png?v=2`,
  //   });
  // }

  if (showFlightTicketPrizeCampaignActive && isLoggedIn) {
    banners.push({
      alt: 'この夏はHafHで海外に行こう!',
      link: routes.coinHistory,
      src: '/images/campaign/flight-ticket-prize/banner.png?v=5',
    });
  }

  if (isCoinPackCampaignActive) {
    banners.push({
      alt: 'coin pack',
      link: routes.coinHistory,
      src: `${activeCoinPackImagePath}banner.png?v=1`,
      type: 'coinpack',
    });
  } else if (showCoinPackPreAnnouncement) {
    banners.push({
      alt: 'coin pack pre',
      link: route(routes.topic, { id: COIN_PACK_CAMPAIGN_TOPIC_ID }),
      src: `${activeCoinPackImagePath}pre-banner.png`,
      type: 'coinpack',
    });
  }

  return banners;
};

export const useCampaignOnClickPushDataLayer = () => {
  const { neighbor } = useNeighbor();
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return ({
    event_name,
    type,
  }: {
    event_name: string;
    type: 'coinpack' | 'earlybird' | 'signup';
  }) => {
    let campaignCode;

    if (type === 'coinpack') {
      campaignCode = campaigns?.coin_pack?.code || '';
    } else if (type === 'earlybird') {
      campaignCode = campaigns?.early_bird?.code || '';
    } else if (type === 'signup') {
      campaignCode = SIGN_UP_CAMPAIGN_CODE;
    } else {
      campaignCode = '';
    }
    const dataLayerObject = {
      campaign_code: campaignCode,
      event: 'gtm-click',
      event_name,
      neighbor_id: neighbor?.id || '',
    };

    return pushDataLayer(dataLayerObject);
  };
};

export const useIsReferralCampaignActive = () => {
  const store = useAppStore();
  const { referralCampaign } = store.getState();

  const now = Date.now();

  return (
    !!referralCampaign?.start_at &&
    !!referralCampaign?.end_at &&
    now >= Date.parse(referralCampaign.start_at) &&
    now <= Date.parse(referralCampaign.end_at)
  );
};

export const useIsWelcomeBackEligible = () => {
  const store = useAppStore();
  const {
    campaign: { campaigns },
  } = store.getState();

  return campaigns?.welcome_back?.status?.eligible;
};

export const useIsSignupDiscountCampaignActive = () => {
  const { isJapan } = useLocation();
  const { isLoggedIn } = useNeighbor();
  const { isRegistrationCountryJapan } = useRegistrationCountry();
  const { neighborPlans } = useAppSelector((state) => ({
    neighborPlans: state.neighborPlans,
  }));

  return (
    (isRegistrationCountryJapan || (!isLoggedIn && isJapan)) &&
    neighborPlans.some(
      (plan) => plan.discount.amount === SIGN_UP_CAMPAIGN_DISCOUNT_AMOUNT
    )
  );
};
