import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import { SIGN_UP_COIN_PACKAGE_CODE } from '@utils/constants';
import { CoinPackCode } from '@utils/types';

export const useActiveCoinPack = (): {
  activeCoinPackCode?: typeof SIGN_UP_COIN_PACKAGE_CODE;
  activeCoinPackImagePath?: string;
  isCoinPackCampaignActive: boolean;
  isSignUpCoinPackageActive: boolean;
  showCoinPackPreAnnouncement: boolean;
} => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));
  const { neighbor } = useNeighbor();

  // When a coin pack is prioritized & purchasable, it becomes "active"
  const activeCoinPackCode =
    ([
      SIGN_UP_COIN_PACKAGE_CODE,
      ...(campaigns?.coin_pack?.package_codes || []),
    ].find(
      (k) => neighbor?.purchase_coin_packages?.[k]?.can_purchase
    ) as CoinPackCode) || null;

  const isSignUpCoinPackageActive =
    activeCoinPackCode === SIGN_UP_COIN_PACKAGE_CODE;

  const isCoinPackCampaignActive =
    (campaigns?.coin_pack?.status?.active &&
      campaigns?.coin_pack?.package_codes?.includes(activeCoinPackCode)) ||
    false;

  const showCoinPackPreAnnouncement = !!(
    campaigns?.coin_pack?.status?.can_be_eligible &&
    Date.now() < Date.parse(campaigns?.coin_pack?.start_at) &&
    Date.now() >= Date.parse(campaigns?.coin_pack?.pre_start_at)
  );

  const activeCoinPackImagePath = `/images/campaign/coin-package/${
    isSignUpCoinPackageActive ? activeCoinPackCode : campaigns?.coin_pack?.code
  }/`;

  return {
    activeCoinPackCode,
    activeCoinPackImagePath,
    isCoinPackCampaignActive,
    isSignUpCoinPackageActive,
    showCoinPackPreAnnouncement,
  };
};
