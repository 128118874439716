import routes, { route } from '@utils/routes';
import router from 'next/router';

export const IS_VALID_RESERVATION_FLOW_KEY = 'isValidReservationFlow';

export const REDIRECT_URL_KEY = 'redirect_url';

export const persistRedirectUrlParams = (
  redirectUrl: string,
  localStorageKey = REDIRECT_URL_KEY
) => {
  if (redirectUrl) {
    localStorage.setItem(localStorageKey, redirectUrl);
  }
};

export const restoreRedirectUrlParams = (localStorageKey = REDIRECT_URL_KEY) =>
  localStorage.getItem(localStorageKey);

export const removeRedirectUrlParams = (localStorageKey = REDIRECT_URL_KEY) => {
  localStorage.removeItem(localStorageKey);
};

// checks if user is not directly accessing from url
export const handleInvalidReservationFlow = (propertyId: string) => {
  const isValidTransition = !!localStorage.getItem(
    IS_VALID_RESERVATION_FLOW_KEY
  );
  localStorage.removeItem(IS_VALID_RESERVATION_FLOW_KEY);

  if (!isValidTransition) {
    if (!propertyId) {
      return router.push({
        pathname: routes.browse,
      });
    }

    return router.push({
      pathname: route(routes.property, { id: propertyId }),
    });
  }
};
