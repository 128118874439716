import { useAppSelector } from '@hooks/useAppSelector';

export const useIsEarlyBirdActive = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return !!campaigns?.early_bird?.status?.active;
};

export const useIsEarlyBirdEligible = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return !!campaigns?.early_bird?.status?.eligible;
};

export const useIsEarlyBirdActiveAndEligible = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return !!(
    campaigns?.early_bird?.status?.active &&
    campaigns?.early_bird?.status?.eligible
  );
};

export const useIsEarlyBirdActiveAndCanBeEligible = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return !!(
    campaigns?.early_bird?.status?.active &&
    campaigns?.early_bird?.status?.can_be_eligible
  );
};

export const useShowEarlyBirdPreAnnouncement = () => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  return !!(
    campaigns?.early_bird?.status?.can_be_eligible &&
    Date.now() < Date.parse(campaigns?.early_bird?.start_at) &&
    Date.now() >= Date.parse(campaigns?.early_bird?.pre_start_at)
  );
};
